export function watchViewport (cb) {
    let timer = null;
    window.addEventListener('resize', () => {
        clearTimeout(timer);
        timer = setTimeout(cb, 100);
    }, false);
}

export function getType () {
    const types = ['mobile', 'tablet', 'desktop'];
    let len = types.length;
    let content;

    if (typeof getComputedStyle === 'function') {
        content = window.getComputedStyle(document.body, ':after').getPropertyValue('content');

        while (len--) {
            if (content.indexOf(types[len]) !== -1) {
                return types[len];
            }
        }
    }

    return types[0];
}

export function isMobile () {
    return getType() == 'mobile';
}

export function isTablet () {
    return getType() == 'tablet';
}

export function isDesktop () {
    return getType() == 'desktop';
}

