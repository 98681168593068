import { SharedPageController } from 'Pararius/Page/Page';

const NAME = 'homeowner-lead-page';

class HomeownerLeadPage extends SharedPageController {
    connect () {
        super.connect();

        this.messageBus.postMessage({
            'message': 'componentConnected',
            'data': {
                'event': 'view',
                'id': 'page',
            },
        });
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': HomeownerLeadPage,
};
