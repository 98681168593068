import form from 'Shared/Component/Form/Form';
import { findOne } from 'Shared/Helper/Dom/Dom';

const SELECTORS = {
    formNumberOfTenants: '.form__number-of-tenants',
};

class ContactAgentHuurprofiel extends form.controller {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'onFormChange',
        ]);
    }

    connect () {
        super.connect();

        this.element.addEventListener('change', this.onFormChange);
        this.displayNumberOfTenantsFormField();
    }

    onFormChange () {
        this.displayNumberOfTenantsFormField();
    }

    displayNumberOfTenantsFormField () {
        const numberOfTenants = findOne(SELECTORS.formNumberOfTenants, this.element);

        numberOfTenants.hidden = this.userIsMovingInTogether();
    }

    userIsMovingInTogether () {
        const optionsToHideNumberOfTenants = ['', '1'];

        return optionsToHideNumberOfTenants.includes(
            this.element.elements['contact_agent_huurprofiel_form[preferred_living_situation]'].value,
        );
    }

    getRecaptchaElement () {
        return findOne('[data-contact-agent-huurprofiel-recaptcha-token]', this.element);
    }

    onSubmit (evt) {
        const hiddenInput = findOne('[name="shim"]', this.element);

        hiddenInput.value = evt.submitter.value;

        super.onSubmit(evt);
    }
}

export default {
    'name': 'contact-agent-huurprofiel',
    'controller': ContactAgentHuurprofiel,
};
