import { ComponentController } from 'Pararius/Helper/Stimulus/ComponentController';
import { searchListSortingControllerFactory } from 'Shared/Component/SearchListSorting/SearchListSortingControllerFactory';

const NAME = 'search-list-sorting';

const SharedSearchListSortingController = searchListSortingControllerFactory({
    'componentController': ComponentController,
});

export default {
    'name': NAME,
    'controller': SharedSearchListSortingController,
};
