import { SharedPageController } from 'Pararius/Page/Page';

const NAME = 'listing-contact-agent-page';

const ATTRS = {
    'trackingType': 'tracking-type',
    'trackingRequestType': 'tracking-request-type',
};

class ListingContactAgentPage extends SharedPageController {
    connect () {
        super.connect();

        // For ga, where the label contact = property, because of legacy.
        const trackingType = this.data.get(ATTRS.trackingType);

        // For conversion stats, where there is a normal label (contact).
        const trackingRequestType = this.data.get(ATTRS.trackingRequestType);

        this.messageBus.postMessage({
            'message': 'componentConnected',
            'data': {
                'event': 'view',
                'id': 'page',
                'replacements': {
                    '%type%': trackingType,
                    '%requestType%': trackingRequestType,
                },
            },
        });
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': ListingContactAgentPage,
};
