import form from 'Shared/Component/Form/Form';
import { findOne } from 'Shared/Helper/Dom/Dom';

const SELECTORS = {
    formNumberOfTenants: '.form__number-of-tenants',
};

class HuurProfiel extends form.controller {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'onFormChange',
        ]);
    }

    connect () {
        super.connect();

        this.element.addEventListener('change', this.onFormChange);
        this.displayNumberOfTenantsFormField();
    }

    onFormChange () {
        this.displayNumberOfTenantsFormField();
    }

    displayNumberOfTenantsFormField () {
        const numberOfTenants = findOne(SELECTORS.formNumberOfTenants, this.element);

        numberOfTenants.hidden = this.userIsMovingInTogether();
    }

    userIsMovingInTogether () {
        const optionsToHideNumberOfTenants = ['', '1'];

        return optionsToHideNumberOfTenants.includes(
            this.element.elements['huur_profiel_form[preferred_living_situation]'].value,
        );
    }
}

export default {
    'name': 'huurprofiel-form',
    'controller': HuurProfiel,
};
