import { Form } from 'Shared/Component/Form/Form';
import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne } from 'Shared/Helper/Dom/Dom';

const NAME = 'homeowner-lead-form';

const CLASSES = {
    'address': renderClasses('form', 'address'),
    'addressHidden': renderClasses('form', 'address', ['hidden']),
    'addressNotFound': renderClasses('form', 'address-not-found'),
    'addressNotFoundHidden': renderClasses('form', 'address-not-found', ['hidden']),
    'loadingIndicator': renderClasses('form', 'loading-indicator'),
};

const SELECTORS = {
    'address': `.${CLASSES.address}`,
    'addressNotFound': `.${CLASSES.addressNotFound}`,
    'loadingIndicator': `.${CLASSES.loadingIndicator}`,
};

class HomeownerLeadForm extends Form {
    connect () {
        super.connect();

        this.postalCode = null;
        this.houseNumber = null;
        this.houseNumberAddition = null;

        this.address = findOne(SELECTORS.address, this.element);
        this.addressNotFound = findOne(SELECTORS.addressNotFound, this.element);

        this.loadingIndicator = findOne(SELECTORS.loadingIndicator, this.element);
    }

    onTextControlValid (evt) {
        super.onTextControlValid(evt);

        const { name, value } = evt.data.data;

        if (!['postal_code', 'house_number', 'house_number_addition'].includes(name)) {
            return;
        }

        if (name == 'postal_code') {
            this.postalCode = value;
        }

        if (name == 'house_number') {
            this.houseNumber = value;
        }

        if (name == 'house_number_addition') {
            let houseNumberAdditionValue = value;
            if (houseNumberAdditionValue == '') {
                houseNumberAdditionValue = null;
            }

            this.houseNumberAddition = houseNumberAdditionValue;
        }

        this.getAddress();
    }

    onTextControlInvalid (evt) {
        super.onTextControlInvalid(evt);

        const name = evt.data.data.name;

        if (name == 'postal_code') {
            this.postalCode = null;
        }

        if (name == 'house_number') {
            this.houseNumber = null;
        }

        if (name == 'house_number_addition') {
            this.houseNumberAddition = null;
        }

        if (!this.houseNumber || !this.postalCode) {
            this.hideError();
            this.hideFields();
        }
    }

    enableFormFields () {
        this.messageBus.postMessage({
            'message': 'enableFormFields',
            'data': {
                'scope': ['street', 'city'],
                'values': {
                    'street': '',
                    'city': '',
                },
            },
        });
    }

    disableFormFields (data) {
        const { streetName, cityName } = data;

        this.messageBus.postMessage({
            'message': 'disableFormFields',
            'data': {
                'scope': ['street', 'city'],
                'values': {
                    'street': streetName,
                    'city': cityName,
                },
            },
        });
    }

    getAddress () {
        if (!this.postalCode || !this.houseNumber) {
            return;
        }

        this.showLoader();
        this.hideError();

        const params = {
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                'postalCode': this.postalCode,
                'houseNumber': this.houseNumber,
                'houseNumberAddition': this.houseNumberAddition,
            }),
            method: 'POST',
        };

        fetch('/frontend/address-lookup', params)
            .then(response => response.json())
            .then(data => {
                this.disableFormFields(data);
                this.showFields();
                this.hideError();
                this.hideLoader();
            })
            .catch(() => {
                this.enableFormFields();
                this.showFields();
                this.showError();
                this.hideLoader();
            });
    }

    showFields () {
        this.address.classList.remove(CLASSES.addressHidden);
    }

    hideFields () {
        this.address.classList.add(CLASSES.addressHidden);
    }

    showLoader () {
        this.loadingIndicator.style.display = 'block';
    }

    hideLoader () {
        this.loadingIndicator.style.display = 'none';
    }

    showError () {
        this.addressNotFound.classList.remove(CLASSES.addressNotFoundHidden);
    }

    hideError () {
        this.addressNotFound.classList.add(CLASSES.addressNotFoundHidden);
    }

    getRecaptchaElement () {
        return findOne('[data-homeowner-lead-form-recaptcha-token]', this.element);
    }
}

export default {
    'name': NAME,
    'controller': HomeownerLeadForm,
};
