import { componentControllerFactory } from 'Shared/Helper/Stimulus/ComponentControllerFactory';
import { trackConversionStats } from 'Shared/Helper/Tracking/ConversionStats';

const dependencies = {
    'trackers': {
        'conversion_stats': trackConversionStats,
    },
};

/**
 * @deprecated
 */
const ComponentController = componentControllerFactory(dependencies);

export { ComponentController };
