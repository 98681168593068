import { ComponentController } from 'Pararius/Helper/Stimulus/ComponentController';
import { searchFiltersControllerFactory } from 'Shared/Component/SearchFilters/SearchFiltersControllerFactory';

const NAME = 'search-filters';

const SharedSearchFiltersController = searchFiltersControllerFactory({
    'componentController': ComponentController,
});

export default {
    'name': NAME,
    'controller': SharedSearchFiltersController,
};
