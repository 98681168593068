import { linear } from 'Helpers/easing/easing';

export function findOne (selector, root=document) {
    return root.querySelector(selector);
}

export function findAll (selector, root=document) {
    return Array.from(root.querySelectorAll(selector));
}

export function hasParent (target, parent) {
    let currentParent = target.parentNode;

    while (currentParent) {
        if (currentParent == parent) {
            return true;
        }

        currentParent = currentParent.parentNode;
    }

    return false;
}

export function findAncestor (parentSelector, element) {
    let currentParent = element.parentNode;
    const matchesMethod = [
        'matches',
        'matchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
        'webkitMatchesSelector',
    ].find(methodName => methodName in element);

    while (currentParent && matchesMethod in currentParent) {
        if (currentParent[matchesMethod](parentSelector)) {
            return currentParent;
        }

        currentParent = currentParent.parentNode;
    }

    return null;
}

export function offsetRelativeToAncestor (element, ancestor) {
    let offsetElement = element;
    let offsetLeft = 0;
    let offsetTop = 0;

    while (offsetElement) {
        offsetLeft += offsetElement.offsetLeft;
        offsetTop += offsetElement.offsetTop;

        offsetElement = offsetElement == ancestor ? null : offsetElement.offsetParent;
    }

    return {
        left: offsetLeft,
        top: offsetTop,
    };
}

export function getJSONAttribute (element, attributeName) {
    const attributeData = element.getAttribute(attributeName);

    if (attributeData) {
        return JSON.parse(attributeData);
    }

    return {};
}

export function animatedScroll (element, to, options = {}) {
    const duration = options.duration || 250;
    const easingFunction = options.easingFunction || linear;
    const startTime = Date.now();
    const beginOffset = element.scrollY || element.scrollTop;
    const totalTravel = to - beginOffset;

    function tick () {
        const now = Date.now();
        const durationRatio = (now - startTime) / duration;
        const progressRatio = easingFunction(durationRatio);
        let newScrollY;

        if (progressRatio >= 1) {
            newScrollY = to;
        } else {
            newScrollY = (totalTravel * progressRatio) + beginOffset;
        }

        element.scrollTo(0, newScrollY);
        // eslint-disable-next-line
        document.body.offsetTop; // trigger layout/paint to make the transition look smoother

        if (progressRatio < 1) {
            requestAnimationFrame(tick);
        }
    }

    requestAnimationFrame(tick);
}

export function getElementVisibilityRatio (element) {
    const domRect = element.getBoundingClientRect();
    const elementLeft = domRect.left;
    const elementTop = domRect.top;
    const elementBottom = domRect.bottom;
    const elementRight = domRect.right;

    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;

    /* eslint-disable */
    if (elementTop > screenHeight || // element is farther down than the bottom of the screen
        elementBottom < 0 ||         // element is farther up than the top of the screen
        elementLeft > screenWidth || // element is farther right than the right edge of the screen
        elementRight < 0             // element is farther left than the left edge of the screen
    ) {
        return 0;
    }
    /* eslint-enable */

    const visibleLeft = elementLeft > 0 ? elementLeft : 0;
    const visibleRight = elementRight < screenWidth ? elementRight : screenWidth;
    const visibleTop = elementTop > 0 ? elementTop : 0;
    const visibleBottom = elementBottom < screenHeight ? elementBottom : screenHeight;
    const visibleWidth = visibleRight - visibleLeft;
    const visibleHeight = visibleBottom - visibleTop;

    const visibleArea = visibleWidth * visibleHeight;
    const elementArea = domRect.width * domRect.height;

    return visibleArea / elementArea;
}
