import { searchFiltersGroupControllerFactory } from 'Shared/Component/SearchFiltersGroup/SearchFiltersGroupControllerFactory';
import { ComponentController } from 'Pararius/Helper/Stimulus/ComponentController';

const NAME = 'search-filters-group';

const SharedSearchFiltersGroupController = searchFiltersGroupControllerFactory({
    'componentController': ComponentController,
});

export default {
    'name': NAME,
    'controller': SharedSearchFiltersGroupController,
};
