const supportedLocales = [
    'de',
    'es',
    'en',
    'fr',
    'it',
    'nl',
];

export { supportedLocales };
