import { agentSummaryControllerFactory } from 'Shared/Component/AgentSummary/AgentSummaryControllerFactory';
import { ComponentController } from 'Pararius/Helper/Stimulus/ComponentController';

const NAME = 'agent-summary';

const SharedAgentSummaryController = agentSummaryControllerFactory({
    'componentController': ComponentController,
});

export default {
    'name': NAME,
    'controller': SharedAgentSummaryController,
};
