import { ComponentController } from 'Pararius/Helper/Stimulus/ComponentController';
import { modalControllerFactory } from 'Shared/Component/Modal/ModalControllerFactory';

const NAME = 'project-premium-signup-modal';

const SharedModalController = modalControllerFactory({
    'componentController': ComponentController,
});

class ProjectPremiumSignupModal extends SharedModalController {
    connect () {
        super.connect();

        this.addPaperForm();
    }

    addPaperForm () {
        const script = document.createElement('script');
        script.src = 'https://paperform.co/__embed';

        document.head.appendChild(script);
    }

    get messageHandlers () {
        return super.messageHandlers;
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': ProjectPremiumSignupModal,
};
