import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, offsetRelativeToAncestor } from 'Helpers/dom/dom';
import { isDesktop } from 'Helpers/viewport/viewport';

import { SharedPageController } from 'Pararius/Page/Page';

const PARENT_NAME = 'page';
const NAME = 'listing-detail-project-premium';

const CLASSES = {
    'developerSummaryAside': renderClasses(PARENT_NAME, 'developer-summary-aside'),
    'rowListing': renderClasses(PARENT_NAME, 'row', ['listing']),
    'listingMapInline': renderClasses(PARENT_NAME, 'wrapper', ['map']),
    'footer': renderClasses(PARENT_NAME, 'wrapper', ['footer']),
};

const SELECTORS = {
    'developerSummaryAside': `.${CLASSES.developerSummaryAside}`,
    'rowListing': `.${CLASSES.rowListing}`,
    'listingMapInline': `.${CLASSES.listingMapInline}`,
    'footer': `.${CLASSES.footer}`,
};

const SPACING = 16;

class ListingDetailProjectPremiumPage extends SharedPageController {
    initialize () {
        this.bindMethodsToSelf([
            'kickOffDeveloperSummaryRaf',
            'scheduleDeveloperSummaryRaf',
            'updateMeasurements',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        const listingMapInline = findOne(SELECTORS.listingMapInline, this.element);
        const footer = findOne(SELECTORS.footer, this.element);

        this.developerSummaryAside = findOne(SELECTORS.developerSummaryAside, this.element);
        this.listingMapInlineOrFooter = listingMapInline || footer;
        this.rowListing = findOne(SELECTORS.rowListing, this.element);

        this.updateMeasurements();

        window.addEventListener('scroll', this.kickOffDeveloperSummaryRaf);
        window.addEventListener('resize', this.kickOffDeveloperSummaryRaf);
    }

    kickOffDeveloperSummaryRaf () {
        if (!this.developerSummaryRaf) {
            if (isDesktop()) {
                this.developerSummaryRaf = requestAnimationFrame(this.scheduleDeveloperSummaryRaf);
            }
        }

        clearTimeout(this.developerSummaryRafTimeout);
        this.developerSummaryRafTimeout = setTimeout(() => {
            cancelAnimationFrame(this.developerSummaryRaf);
            this.developerSummaryRaf = null;
        }, 250);
    }

    scheduleDeveloperSummaryRaf () {
        this.updateMeasurements();
        this.repositiondeveloperSummaryAside();
        this.developerSummaryRaf = requestAnimationFrame(this.scheduleDeveloperSummaryRaf);
    }

    repositiondeveloperSummaryAside () {
        let position = '';
        let top = '';
        let right = '';

        if (isDesktop()) {
            const mapOffsetTop = this.elemPositions.listingMapInlineOrFooter.top;
            const developerSummaryHeight = this.elemPositions.developerSummaryAside.height;

            // Use .page__row--listing as surrogate for the top and left measurements of .Developer-summary in non-fixed state.
            // Measuring .Developer-summary while it is fixed will give undesirable results.
            const rowListingTop = this.elemPositions.rowListing.top;
            const rowListingRight = this.elemPositions.rowListing.left + this.elemPositions.rowListing.width;

            const scrolledFarEnoughToBeFixed = pageYOffset >= rowListingTop - SPACING;
            const scrolledFarEnoughToBeBlockedByMap = pageYOffset >= mapOffsetTop - developerSummaryHeight - (SPACING * 2);

            if (scrolledFarEnoughToBeFixed && !scrolledFarEnoughToBeBlockedByMap) {
                position = 'fixed';
                top = SPACING;
                right = document.body.clientWidth - rowListingRight + SPACING;
            } else if (scrolledFarEnoughToBeBlockedByMap) {
                position = 'absolute';
                top = mapOffsetTop - rowListingTop - developerSummaryHeight - (SPACING * 2);
            }

            this.developerSummaryAside.style.position = position;
            this.developerSummaryAside.style.top = top ? `${top}px` : '';
            this.developerSummaryAside.style.right = right ? `${right}px` : '';
        }
    }

    updateMeasurements () {
        const developerSummaryAsideOffsets = offsetRelativeToAncestor(this.developerSummaryAside, document.body);
        const listingMapInlineOrFooterOffsets = offsetRelativeToAncestor(this.listingMapInlineOrFooter, document.body);
        const rowListingOffsets = offsetRelativeToAncestor(this.rowListing, document.body);

        this.elemPositions = {
            rowListing: {
                top: rowListingOffsets.top,
                left: rowListingOffsets.left,
                width: this.rowListing.clientWidth,
            },
            developerSummaryAside: {
                top: developerSummaryAsideOffsets.top,
                height: this.developerSummaryAside.clientHeight,
            },
            listingMapInlineOrFooter: {
                top: listingMapInlineOrFooterOffsets.top,
                height: this.listingMapInlineOrFooter.clientHeight,
            },
        };
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': ListingDetailProjectPremiumPage,
};
