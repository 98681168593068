import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, offsetRelativeToAncestor } from 'Helpers/dom/dom';
import { isDesktop } from 'Helpers/viewport/viewport';
import { SharedPageController } from 'Pararius/Page/Page';

const PARENT_NAME = 'page';
const NAME = 'agent-detail-page';

const CLASSES = {
    'pageSidebar': renderClasses(PARENT_NAME, 'sidebar'),
};

const SELECTORS = {
    'pageSidebar': `.${CLASSES.pageSidebar}`,
};

const SPACING = 16;

class AgentDetailPage extends SharedPageController {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'kickOffRepositionRaf',
            'scheduleRepositionRaf',
        ]);
    }

    connect () {
        super.connect();

        window.addEventListener('resize', this.kickOffRepositionRaf);
        window.addEventListener('scroll', this.kickOffRepositionRaf);
    }

    kickOffRepositionRaf () {
        if (!isDesktop()) {
            this.resetPositionStyles();
            return;
        }

        if (!this.repositionRaf) {
            this.repositionRaf = requestAnimationFrame(this.scheduleRepositionRaf);
        }

        clearTimeout(this.repositionRafTimeout);
        this.repositionRafTimeout = setTimeout(() => {
            cancelAnimationFrame(this.repositionRaf);
            this.repositionRaf = null;
        }, 250);
    }

    scheduleRepositionRaf () {
        this.repositionAgentDetailIntro();
        this.repositionRaf = requestAnimationFrame(this.scheduleRepositionRaf);
    }

    resetPositionStyles () {
        const agentDetailIntro = findOne('.agent-detail-intro', this.element);
        const pageSidebar = findOne(SELECTORS.pageSidebar, this.element);

        pageSidebar.style.minHeight = '';

        agentDetailIntro.style.position = '';
        agentDetailIntro.style.top = '';
        agentDetailIntro.style.width = '';
    }

    repositionAgentDetailIntro () {
        const agentDetailIntro = findOne('.agent-detail-intro', this.element);
        const pageSidebar = findOne(SELECTORS.pageSidebar, this.element);
        const map = findOne('#map');
        const footer = findOne('.footer');

        const blockingElement = map || footer;

        const pageSidebarOffsets = offsetRelativeToAncestor(pageSidebar, document.body);
        const blockingElementOffsets = offsetRelativeToAncestor(blockingElement, document.body);
        const agentDetailIntroHeight = agentDetailIntro.clientHeight;

        const styles = {
            position: '',
            top: '',
            width: '',
        };

        // Is the page scrolled beyond the top of the sidebar
        if (pageYOffset > pageSidebarOffsets.top - SPACING) {
            styles.position = 'fixed';
            styles.top = `${SPACING}px`;
            styles.width = `${pageSidebar.offsetWidth}px`;

            // Is the page scrolled beyond the point where there is not enough room above the map to show .agent-detail-intro
            if (blockingElementOffsets.top - pageYOffset < agentDetailIntroHeight + (2*SPACING)) {
                styles.position = 'absolute';
                styles.top = `${blockingElementOffsets.top - pageSidebarOffsets.top - agentDetailIntroHeight - SPACING}px`;
            }
        }

        // In case .agent-detail-intro has more height than the main content, give .page__sidebar some min-height
        // so the map doesn't jerk upwards because the .agent-detail-intro is out of the document flow and .page__sidebar
        // suddenly has no intrinsic height
        pageSidebar.style.minHeight = `${agentDetailIntroHeight}px`;

        agentDetailIntro.style.position = styles.position;
        agentDetailIntro.style.top = styles.top;
        agentDetailIntro.style.width = styles.width;
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': AgentDetailPage,
};
