import { PageController as SharedPageController } from 'Shared/Page/Page';

const NAME = 'page';

class PageController extends SharedPageController {
}

// export for extending
export { SharedPageController, PageController };

// export for direct use
export default {
    'name': NAME,
    'controller': PageController,
};
