const supportedLocales = [
    'de',
    'es',
    'en',
    'fr',
    'it',
    'nl',
];

const requestedLocaleIsSupported = supportedLocales.indexOf(window.locale) > -1;
const LOCALE = requestedLocaleIsSupported ? window.locale : 'nl';

export { LOCALE };
