import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne } from 'Shared/Helper/Dom/Dom';

const NAME = 'agent-summary';

const CLASSES = {
    'showPhoneButton': renderClasses(NAME, 'link', ['show-phone']),
    'callAgentLink': renderClasses(NAME, 'link', ['call-agent']),
    'hiddenLink': renderClasses(NAME, 'link', ['hidden']),
};

const SELECTORS = {
    'showPhoneButton': `.${CLASSES.showPhoneButton}`,
    'callAgentLink': `.${CLASSES.callAgentLink}`,
};

function agentSummaryControllerFactory (dependencies) {
    return class AgentSummary extends dependencies.componentController {
        onShowPhoneClick () {
            const showPhoneButton = findOne(SELECTORS.showPhoneButton, this.element);
            const callAgentLink = findOne(SELECTORS.callAgentLink, this.element);

            showPhoneButton.parentElement.removeChild(showPhoneButton);
            callAgentLink.classList.remove(CLASSES.hiddenLink);
        }

        get componentName () {
            return NAME;
        }
    };
}

export { agentSummaryControllerFactory };
